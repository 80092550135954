@font-face {
  font-family: 'Roboto Black';
  src: url('./assets/fonts/Roboto/Roboto-Black.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Black Italic';
  src: url('./assets/fonts/Roboto/Roboto-BlackItalic.ttf');
  font-weight: bold;
  font-style: italic;
}
 
@font-face {
  font-family: 'Roboto Bold';
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Bold Italic';
  src: url('./assets/fonts/Roboto/Roboto-BoldItalic.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Italic';
  src: url('./assets/fonts/Roboto/Roboto-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('./assets/fonts/Roboto/Roboto-Light.ttf');
  font-weight: 100;
  font-style: normal;
}
 
@font-face {
  font-family: 'Roboto Light Italic';
  src: url('./assets/fonts/Roboto/Roboto-LightItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 300;
  font-style: normal;
}
 
@font-face {
  font-family: 'Roboto Medium Italic';
  src: url('./assets/fonts/Roboto/Roboto-MediumItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Thin';
  src: url('./assets/fonts/Roboto/Roboto-Thin.ttf');
  font-weight: 200;
  font-style: normal;
}
 
@font-face {
  font-family: 'Roboto Thin Italic';
  src: url('./assets/fonts/Roboto/Roboto-ThinItalic.ttf');
  font-weight: 200;
  font-style: italic;
}
 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  margin: 10px;
}

.login-container {
  margin-top: 10%;
}

.email-field {
  width: 80% !important;
  margin: 40px 40px 0 !important;
}

.field-error {
  color: #F00;
  font-size: 12px;
}

.btn-login {
  margin-top: 40px !important;
}

.first-page {
  height: 23px;
}
.previous-page {
  height: 33px;
}
.next-page {
  height: 35px;
}
.last-page {
  height: 25px;
}

.search-icon {
  width: 22px;
  height: 22px;
  margin-right: 4px;
}
.close-icon {
  width: 22px;
  height: 22px;
  top: 2px;
}

.MuiButton-root.btn-apply, .MuiButton-root.btn-clear {
  position: relative;
  top: -2px;
  margin-left: 13px;
  margin-right: 0;
  padding-left: 20px;
  padding-right: 20px;
  width: 100px;
}

.notistack-SnackbarContainer {
  left: 12.5% !important;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.MuiTableCell-body .positive {
  color: #00CCCC;
}

.MuiTableCell-body .negative {
  color: #FF4534;
}